<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <div style="height: 60px">
      <v-row>
        <v-col cols="12" class="d-flex align-items-center pb-0 pt-0">
          <div>
            <label class="text-body-1 font-weight-medium mb-6 pt-4">Từ:</label>
            <date-picker
              style=""
              class="ml-2"
              v-model="date_start_input"
              value-type="format"
              format="YYYY-MM-DD"
            />
          </div>
          <v-icon class="ml-2 mr-2 mb-3">mdi-arrow-right</v-icon>
          <div>
            <label class="text-body-1 font-weight-medium mb-6 pt-4">Đến:</label>
            <date-picker
              style=""
              class="ml-2"
              v-model="date_end_input"
              value-type="format"
              format="YYYY-MM-DD"
            />
          </div>
          <v-btn
            color="cyan"
            class="ma-2 font-weight-bold"
            outlined
            large
            @click="btnStatistical"
          >
            <v-icon left>mdi-magnify</v-icon> Thống kê
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex align-items-center flex-wrap statistical-fee p-6">
      <!--begin: Item-->
      <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
        <span class="mr-4">
          <!-- <i class="flaticon-piggy-bank icon-2x text-muted font-weight-bold"></i> -->
          <v-icon>mdi-piggy-bank</v-icon>
        </span>
        <div class="d-flex flex-column text-dark-75">
          <span class="font-weight-bolder font-size-sm">Doanh thu</span>
          <span class="font-weight-bolder font-size-h5">
            {{ total_fee | formatNumber }}
            <span class="text-dark-50 font-weight-bold">VNĐ</span>
          </span>
        </div>
      </div>
      <!--end: Item-->
      <!--begin: Item-->
      <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
        <span class="mr-4">
          <i class="flaticon-confetti icon-2x text-muted font-weight-bold"></i>
        </span>
        <div class="d-flex flex-column text-dark-75">
          <span class="font-weight-bolder font-size-sm"
            >Tổng số coupon đã dùng</span
          >
          <span class="font-weight-bolder font-size-h5">
            {{ data_revenue.length | formatNumber }}
          </span>
        </div>
      </div>
      <!--end: Item-->
    </div>
    <div class="mt-2">
      <v-row>
        <v-col cols="12">
          <div class="table-responsive">
            <table
              class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
            >
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Code</th>
                  <th scope="col">Số tiền giảm</th>
                  <th scope="col">Số lần sử dụng</th>
                  <th scope="col">Doanh thu từ coupon</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(coupon, i) in data_revenue">
                  <tr :key="i">
                    <td scope="row">{{ i + 1 }}</td>
                    <td>
                      <p
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ coupon.coupon.code }}
                      </p>
                    </td>
                    <td>
                      <p
                        class="text-dark-75 font-weight-bolder d-block font-size-lg amber--text text--lighten-1"
                      >
                        {{ coupon.coupon.discount_amount | formatNumber }} VND
                      </p>
                    </td>
                    <td>
                      <p
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ coupon.no_of_number_use | formatNumber }}
                      </p>
                    </td>
                    <td>
                      <p
                        class="text-dark-75 font-weight-bolder d-block font-size-lg blue--text text--lighten-1"
                      >
                        {{ coupon.revenue_from_coupon | formatNumber }} VND
                      </p>
                    </td>
                    <td>
                      <div style="display: flex">
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <button
                              class="btn btn-icon btn-light-primary btn-sm ml-2"
                              v-bind="attrs"
                              v-on="on"
                              @click="showOrderList(coupon.coupon.id)"
                            >
                              <span
                                class="svg-icon svg-icon-md svg-icon-primary"
                              >
                                <v-icon color=""
                                  >mdi-clipboard-list-outline</v-icon
                                >
                              </span>
                            </button>
                          </template>
                          <span>Danh sách order</span>
                        </v-tooltip>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="!data_revenue.length">
                  <tr>
                    <td style="text-align: center" colspan="8">No data</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </v-col>
      </v-row>
    </div>

    <OrderListModal
      :coupon_id="coupon_id"
      :show_dialog="dialogOrderList"
      :date_start="date_start_input"
      :date_end="date_end_input"
      :is_revenue="true"
      @setDialog="(e) => (this.dialogOrderList = e)"
    ></OrderListModal>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
import OrderListModal from "@/view/pages/coupons/modal/OrderList";
export default {
  name: "StatisticalManagement",
  components: {
    OrderListModal,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      total_fee: 0,
      data_revenue: [],
      is_call_api: false,
      date_start_input: null,
      date_end_input: null,
      coupon_id: null,
      dialogOrderList: false,
    };
  },
  created() {
    this.getDataStatisticalFromCoupon();
  },
  methods: {
    async getDataStatisticalFromCoupon() {
      let vm = this;
      let data = {};
      if (this.date_start_input != null)
        data.date_start = this.date_start_input;
      if (this.date_end_input != null) data.date_end = this.date_end_input;
      try {
        vm.is_call_api = true;
        let res = await ApiService.post(
          "prep-app/statistical/statistical-revenue-fee",
          data
        );
        if (res.status == 200) {
          vm.total_fee = res.data.total_fee;
          vm.data_revenue = res.data.data_revenue;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        if (error.response.status == 422) {
          let data_response_error =
            error.response.data.error.message.split(".<br />");
          vm.showError(data_response_error);
        }
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    btnStatistical() {
      this.getDataStatisticalFromCoupon();
    },
    showOrderList(coupon_id) {
      this.coupon_id = coupon_id;
      this.dialogOrderList = true;
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.statistical-fee {
  border: 1px solid #9e9e9e73;
  border-radius: 15px;
}
</style>
