<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogOrderList"
        max-width="1400px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Danh sách hóa đơn đã dùng coupon</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogOrderList = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div class="table-responsive">
                    <table
                      class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
                    >
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">student name</th>
                          <th scope="col">programme</th>
                          <th scope="col">price</th>
                          <th scope="col">date</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(order, i) in orders">
                          <tr :key="i">
                            <td scope="row">{{ i + 1 }}</td>
                            <td>
                              <p class="mb-0">
                                <span
                                  class="text-dark-75 font-weight-bolder font-size-lg"
                                  >Name:
                                </span>
                                <span
                                  class="text-dark-75 blue-grey--text text--lighten-1 font-size-lg"
                                  >{{ order.user_name }}</span
                                >
                              </p>
                              <p class="mb-0">
                                <span
                                  class="text-dark-75 font-weight-bolder font-size-lg"
                                  >Phone:
                                </span>
                                <span
                                  class="text-dark-75 blue-grey--text text--lighten-1 font-size-lg"
                                  >{{ order.user_phone }}</span
                                >
                              </p>
                            </td>
                            <td>
                              <p
                                class="text-dark-75 font-weight-bolder d-block font-size-lg"
                              >
                                {{ order.programme_name.vi }}
                              </p>
                            </td>
                            <td>
                              <p class="mb-0">
                                <span
                                  class="text-dark-75 font-weight-bolder font-size-lg"
                                  >Fee:
                                </span>
                                <span
                                  class="text-dark-75 blue-grey--text text--lighten-1 font-size-lg"
                                  >{{ order.fee | formatNumber }}</span
                                >
                              </p>
                              <p class="mb-0">
                                <span
                                  class="text-dark-75 font-weight-bolder font-size-lg"
                                  >Discount:
                                </span>
                                <span
                                  class="text-dark-75 blue-grey--text text--lighten-1 font-size-lg"
                                  >{{
                                    order.discount_amount | formatNumber
                                  }}</span
                                >
                              </p>
                              <p class="mb-0">
                                <span
                                  class="text-dark-75 font-weight-bolder font-size-lg"
                                  >Fee after discount:
                                </span>
                                <span
                                  class="text-dark-75 blue-grey--text text--lighten-1 font-size-lg"
                                  >{{
                                    order.fee_after_discount | formatNumber
                                  }}</span
                                >
                              </p>
                            </td>
                            <td>
                              <p
                                class="text-dark-75 font-weight-bolder d-block font-size-lg"
                              >
                                {{
                                  order.date_issued | convertTimestampToDMY_HMS
                                }}
                              </p>
                            </td>
                            <td>
                              <div style="display: flex">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <router-link
                                      :to="{
                                        name: 'StudentEdit',
                                        params: { id: order.user_id },
                                      }"
                                      target="_blank"
                                    >
                                      <button
                                        class="btn btn-icon btn-light-success btn-sm ml-4"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <span
                                          class="svg-icon svg-icon-md svg-icon-primary"
                                        >
                                          <v-icon color="">mdi-account</v-icon>
                                        </span>
                                      </button>
                                    </router-link>
                                  </template>
                                  <span>Profile Student</span>
                                </v-tooltip>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogOrderList = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "OrderListModal",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    coupon_id: {
      type: Number,
      default: null,
    },
    is_revenue: {
      type: Boolean,
      default: false,
    },
    date_start: {},
    date_end: {},
  },
  data() {
    return {
      is_call_api: false,
      orders: [],
    };
  },
  computed: {
    dialogOrderList: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    show_dialog(val) {
      if (val) {
        this.getOrders();
      }
    },
  },
  methods: {
    async getOrders() {
      if (this.coupon_id) {
        let vm = this;
        if (this.is_revenue) {
          try {
            vm.is_call_api = true;
            let url = "prep-app/coupon/" + this.coupon_id + "/order-revenue?";
            if (this.date_start != null) {
              url = url + "&date_start=" + this.date_start;
            }
            if (this.date_end != null) {
              url = url + "&date_end=" + this.date_end;
            }
            let res = await ApiService.get(url);
            if (res.status === 200) {
              vm.orders = res.data;
              vm.is_call_api = false;
            }
          } catch (error) {
            vm.is_call_api = false;
          }
        } else {
          try {
            vm.is_call_api = true;
            let res = await ApiService.get(
              "prep-app/coupon/" + this.coupon_id + "/orders"
            );
            if (res.status === 200) {
              vm.orders = res.data;
              vm.is_call_api = false;
            }
          } catch (error) {
            vm.is_call_api = false;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
